import { useEffect, useState } from "react";

import {
  Button,
  Container,
  Table,
  ButtonGroup,
  Navbar,
  Modal,
  ProgressBar,
  Form,
  FormGroup,
  InputGroup,
  Card,
  FormCheck
} from "react-bootstrap";

import "./App.css";

const BusinessCard = {
  Name: "",
  Mail: "",
  Telefon: "",
  Mobile: "",
  Position: [],
  Address: [],
};

const MAILDOMAIN = "aero.bombardier.com"

function LoadingModal(props) {
  return (
    <Modal {...props} centered onHide={() => { }}>
      <Modal.Header>
        <Modal.Title>Erstelle Visitenkarten...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressBar animated striped now={100} />
      </Modal.Body>
    </Modal>
  );
}

function restoreData() {
  let local = localStorage.getItem("cards.data");
  if (local) {
    return JSON.parse(local) || [];
  }
  return [];
}

function persistData(data) {
  localStorage.setItem("cards.data", JSON.stringify(data));
}

const App = () => {
  const [tokenIn, setTokenIn] = useState("");
  const [token, setToken] = useState(localStorage.getItem("cards.token"));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(restoreData());

  const [current, setCurrent] = useState(BusinessCard);
  const [edit, setEdit] = useState(-1);

  const [all, setAll] = useState(localStorage.getItem('cards.all') ? localStorage.getItem('cards.all') === 'true' : true);
  const [selection, setSelection] = useState(JSON.parse(localStorage.getItem('cards.selection') || '{}'))
  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setCurrent({ ...current, [key]: value });
  };

  useEffect(() => {
    persistData(data);
  }, [data]);

  useEffect(() => {
    localStorage.setItem("cards.token", token || "");
  }, [token]);

  useEffect(() => {
    localStorage.setItem('cards.all', `${all}`)
  }, [all])

  useEffect(() => {
    localStorage.setItem('cards.selection', JSON.stringify(selection))
    console.log(selection)
  }, [selection])

  const onSubmit = (e) => {
    e.preventDefault()
    if (loading) {
      return
    }
    if (edit !== -1) {
      setData([
        ...data.slice(0, edit),
        current,
        ...data.slice(edit + 1),
      ]);
      setCurrent(BusinessCard);
      setEdit(-1);
    } else {
      if (!current.Address || current.Address.length === 0) {
        current.Address = ['Bombardier Aviation Services Berlin GmbH', 'Walter-Rieseler-Strasse 1 | Bldg. G3710', '12529 Schoenefeld | Germany']
      }
      setData([...data, current]);
      setCurrent(BusinessCard);
    }
  }

  if (!token) {
    return (
      <Container>
        <Card style={{ width: '21rem', margin: '0 auto', marginTop: '10rem' }}>
          <Card.Body>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                fetch("/api/auth?token=" + tokenIn, { method: "POST" }).then(
                  (resp) => {
                    if (resp.status === 200) {
                      setToken(tokenIn);
                    } else {
                      setToken("");
                    }
                  }
                );
              }}
            >
              <Form.Group>
                <Form.Control
                  placeholder="Enter key"
                  style={{ fontSize: "1.5rem" }}
                  onChange={(e) => setTokenIn(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" block>Senden</Button>

            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  return (
    <>
      <Navbar sticky="top" bg="light" className="justify-content-end">
        <Button
          onClick={async () => {
            if (data.length === 0) {
              return;
            }

            const selectedData = all ? data : data.filter((_, index) => selection[`${index}`]);

            if (selectedData.length === 0) {
              return;
            }


            setLoading(true);

            var request = new XMLHttpRequest();
            request.open("POST", `/api/generate?token=${token}`, true);
            request.setRequestHeader(
              "Content-Type",
              "application/json; charset=UTF-8"
            );
            request.responseType = "blob";
            request.onload = function () {
              setLoading(false);
              // Only handle status code 200
              if (request.status === 200) {
                var filename = "cards.zip";
                // The actual download
                var blob = new Blob([request.response], {
                  type: "application/zip",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
              } else if (request.status === 401) {
                setToken("");
              }
            };

            request.send(JSON.stringify(selectedData));
          }}
          disabled={edit !== -1 || loading}
        >
          Visitenkarten herunterladen
        </Button>
      </Navbar>
      <Container fluid>
        <Form onSubmit={onSubmit}>
          <Table striped hover>
            <thead>
              <tr>
                <th><FormCheck checked={all} onChange={(_) => {
                  setAll(!all)
                }} /></th>
                <th>#</th>
                <th>Name</th>
                <th>Position</th>
                <th>Address</th>
                <th>E-Mail</th>
                <th>Telefon</th>
                <th>Mobile</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                const editingRow = edit === index;
                const selected = selection[`${index}`] === true;
                return (
                  <tr key={index} className={editingRow ? "Editing" : ""}>
                    <td><FormCheck checked={all || selected} onChange={_ => {
                      setSelection({ ...selection, [`${index}`]: !selected });
                    }} /></td>
                    <td>{index + 1}</td>
                    <td>{item.Name}</td>
                    <td className="Multiline">{item.Position.join("\n")}</td>
                    <td className="Multiline">{item.Address.join("\n")}</td>
                    <td>{item.Mail}@{MAILDOMAIN}</td>
                    <td>{item.Telefon}</td>
                    <td>{item.Mobile}</td>
                    <td>
                      <ButtonGroup>
                        <Button
                          variant="light"
                          disabled={editingRow || loading}
                          onClick={() => {
                            setEdit(index);
                            setCurrent(item);
                          }}
                        >
                          bearbeiten
                        </Button>
                        <Button
                          variant="danger"
                          disabled={editingRow || loading}
                          onClick={() => {
                            setData([
                              ...data.slice(0, index),
                              ...data.slice(index + 1),
                            ]);
                          }}
                        >
                          l&ouml;schen
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td>
                  <Form.Group controlId="Name">
                    <Form.Control
                      name="Name"
                      value={current.Name}
                      onChange={onChange}
                    />
                  </Form.Group>
                </td>
                <td>
                  <FormGroup>
                    <Form.Control
                      required
                      as="textarea"
                      style={{ resize: "none" }}
                      placeholder="max. 2 Zeilen..."
                      rows={2}
                      value={current.Position.join("\n")}
                      onChange={(e) => {
                        const lines = e.target.value.split("\n");
                        if (lines.length <= 2) {
                          setCurrent({
                            ...current,
                            Position: lines,
                          });
                        }
                      }}
                    />
                  </FormGroup>
                </td>
                <td>
                  <Form.Group>
                    <Form.Control
                      required
                      as="textarea"
                      style={{ resize: "none" }}
                      placeholder={`Bombardier Aviation Services Berlin GmbH
Walter-Rieseler-Strasse 1 | Bldg. G3710
12529 Schoenefeld | Germany`}
                      rows={3}
                      readOnly={edit === -1}
                      value={current.Address.join("\n")}
                      onChange={(e) => {
                        const lines = e.target.value.split("\n");
                        if (lines.length <= 3) {
                          setCurrent({
                            ...current,
                            Address: lines,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        required
                        name="Mail"
                        value={current.Mail}
                        onChange={onChange}
                        style={{ display: "inline-block" }}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>@{MAILDOMAIN}</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </td>
                <td>
                  <Form.Group>
                    <Form.Control
                      type="phone"
                      name="Telefon"
                      value={current.Telefon}
                      onChange={onChange}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group>
                    <Form.Control
                      type="phone"
                      name="Mobile"
                      value={current.Mobile}
                      onChange={onChange}
                    />
                  </Form.Group>
                </td>
                <td>
                  {edit === -1 && (
                    <ButtonGroup>
                      <Button
                        variant="primary"
                        disabled={loading}
                        type="submit"
                      >
                        hinzuf&uuml;gen
                      </Button>
                      <Button
                        variant="light"
                        disabled={loading}
                        onClick={() => {
                          setCurrent(BusinessCard);
                        }}
                      >
                        l&ouml;schen
                      </Button>
                    </ButtonGroup>
                  )}
                  {edit !== -1 && (
                    <ButtonGroup>
                      <Button
                        variant="primary"
                        disabled={loading}
                        type="submit"
                      >
                        Speichern
                      </Button>
                      <Button
                        variant="secondary"
                        disabled={loading}
                        onClick={() => {
                          setCurrent(BusinessCard);
                          setEdit(-1);
                        }}
                      >
                        Abbrechen
                      </Button>
                    </ButtonGroup>

                  )}
                  {data.length > 10 && (
                    <Button block style={{ marginTop: '3rem' }} variant="danger" size="sm" onClick={() => {
                      if (window.confirm("Alle Einträge entfernen?")) {
                        setData([]);
                      }
                    }}>
                      Liste l&ouml;schen
                    </Button>
                  )} 
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </Container>
      <LoadingModal show={loading} />
    </>
  );
}

export default App;